<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import api from '@/services/secureHttps';
import { mapGetters } from 'vuex';
import Spinner from "@/components/spinner";

export default {
    props: {
    },
    components: {
        Layout,
        PageHeader,
        Spinner
    },

    data() {
        return {
            trackedPagesData: [],
            page: 1,
            perPage: 30,
            pages: [],
            title: "View Page Performance",
            isLoading : false
        };
    },

    mounted() {
        this.getAllTrackedPages();
    },

    computed: {
        ...mapGetters('context', [
            'selectedWebisteId', 'selectedOrganisationId'
        ]),

        displayedPages() {
            return this.paginate(this.trackedPagesData);
        },

        resultQuery() {
            /*if (this.searchQuery) {
                const search = this.searchQuery.toLowerCase();
                return this.displayedPages.filter((data) => {
                    return (
                        data.url.toLowerCase().includes(search)
                    );
                });
            } else {*/
            return this.displayedPages;
            //}
        }
    },

    methods: {
        getAllTrackedPages() {
            if (typeof this.selectedOrganisationId === 'undefined' || typeof this.selectedWebisteId === 'undefined') {
                return;
            }

            api.getAllTrackedPages(this.selectedWebisteId, this.selectedORganisationId)
                .then(response => {
                    console.log("tracked pages resp:", response);
                    this.trackedPagesData = response.data;
                })
                .finally(() => {
                    this.isLoading = false;
                });

        },

        submitUrl() {

        },
        paginate(data) {
            let page = this.page;
            let perPage = this.perPage;
            let from = page * perPage - perPage;
            let to = page * perPage;
            return data.slice(from, to);
        },

        setPages() {
            let numberOfPages = Math.ceil(this.trackedPagesData.length / this.perPage);
            for (let index = 1; index <= numberOfPages; index++) {
                this.pages.push(index);
            }
        },

        handleOnPageSelected(pageInfoObj) {
            console.log("trackPAgeIfno");
            console.log(pageInfoObj.id);

            api.trackPage(this.selectedOrganisationId, this.selectedWebisteId, pageInfoObj.id)
                .then(() => {
                    this.getAllTrackedPages();
                });
        },

        navigateToPageInfo(selectedPage) {
            this.$router.push({ name: "pageDataPlatform", params: { pageId: `${selectedPage.id}`, websiteId: `${this.selectedWebisteId}`, organisationId: `${this.selectedOrganisationId}` } });
        },

        updateUrls() {
            console.log(this.trackedPagesData);
            var selectedPageIds = this.trackedPagesData.filter(x => x.isSelected).map(x => x.id);
            this.isLoading = true;
            api.removeTrackedPages(this.selectedOrganisationId, this.selectedWebisteId, selectedPageIds)
                .then(() => {
                    this.getAllTrackedPages();
                });
        }
    }
}
</script>
            
<template>
    <Layout>
        <PageHeader :title="title" />
        <div class="row">

            <!-- end col -->
            <div class="col">
                <!-- card -->
                <div class="card card-height-100">
                    <div class="card-header align-items-center d-flex">
                        <p class="
                            text-uppercase
                            fw-bold
                            text-truncate
                            mb-0
                            ">
                            Select page to view performance
                        </p>
                    </div>
                    <!-- end card header -->

                    <!-- card body -->
                    <div class="card-body">

                        <!-- start error block -->
                        <!-- <div id="errorMsg" v-show="errorMessages.length > 0">
                                <div class="card-body">
                                    <div class="card-title">Sitemap Import Errors</div>
                                    <ul class="list-group">
                                        <li v-for="error in errorMessages" :key="error.id"
                                            class="list-group-item list-group-item-danger">
                                            {{ error }}</li>
                                    </ul>
                                </div>
                            </div> -->
                        <!-- end error block -->
                        <p>View performance of a page by selecting the URL or select the check box and remove page from
                            continually monitoring.</p>
                        <!-- start table -->
                        <div class="card-body">
                            <div class="table-responsive table-card">
                                <table class="table align-middle table-nowrap" id="customerTable">
                                    <thead class="table-light text-muted">
                                        <tr>
                                            <th class="sort" data-sort="currency_name" scope="col">Url</th>

                                            <th scope="col">Select</th>
                                        </tr>
                                    </thead>
                                    <!--end thead-->
                                    <tbody class="list form-check-all">
                                        <tr v-for="(data, index) of resultQuery" :key="index">
                                            <td class="id" @click="navigateToPageInfo(data)">
                                                {{ data.path }}
                                            </td>

                                            <td>
                                                <input v-model="data.isSelected" class="form-check-input mt-0"
                                                    type="checkbox" aria-label="Import url">
                                            </td>
                                        </tr>
                                        <tr v-show="isLoading">
                                            <td colspan="2">   <Spinner :isLoading="isLoading"></Spinner></td>
                                        </tr>
                                    </tbody>
                                    <!--end tbody-->
                                </table>
                            </div>
                            <div class="d-flex justify-content-end mt-3">
                                <div class="pagination-wrap hstack gap-2">
                                    <a class="page-item pagination-prev disabled" href="#" v-if="page != 1" @click="page--">
                                        Previous
                                    </a>
                                    <ul id="pageIndex" class="pagination listjs-pagination mb-0">
                                        <li :class="{
                                            active: pageNumber == page,
                                            disabled: pageNumber == '...',
                                        }" v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)" :key="index"
                                            @click="page = pageNumber">
                                            <a class="page" href="#">{{ pageNumber }}</a>
                                        </li>
                                    </ul>
                                    <a class="page-item pagination-next" href="#" @click="page++"
                                        v-if="page < pages.length">
                                        Next
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!-- end table -->
                        <!-- <div v-if="addUrlErrorMessage !== ''" class="bg-danger">
                                {{ addUrlErrorMessage }}
                            </div> -->
                        <button variant="primary" class="btn btn-primary btn-animation waves-effect waves-light float-end"
                            data-text="Remove Tracked Pages" @click="updateUrls()"><span>Remove Tracked
                                Pages</span></button>
                    </div>
                    <!-- end card body -->
                </div>
                <!-- end card -->
            </div>
            <!-- end col -->
        </div>
    </Layout>
</template>